(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbCard.wbFaq.factory:Faq
   *
   * @description
   *
   */
  angular
    .module('wbFaq')
    .factory('Faq', Faq);

  function Faq(wbResource) {
    var url = 'faqs'
      , FaqBase = wbResource('faq', url);

    return FaqBase;
  }
}());
